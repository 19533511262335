import { useEffect, useState } from "react";
import { color_neutral_3, Cookies as CookiesComponent } from "zera";
import { showTexts, updateDimensions, cookies } from "../../helpers";
import { Container, HiperLinkText, ContedHiperLink } from "./styles";

interface ICookiesProps {
  internationalization: "enUS" | "es" | "ptBR";
}

export const Cookies: React.FunctionComponent<ICookiesProps> = (props) => {
  const showTextsi18n = showTexts(props.internationalization);

  const [cookiesView, setCookiesView] = useState(true);
  const [width, setWindowWidth] = useState(0);

  const showDesktopComponent: boolean = width >= 460;

  useEffect(() => {
    const getCookies = cookies.getCookie("Aceite_Cookies");
    const hasCookies = getCookies !== null;
    const local = localStorage.getItem("pureJavaScriptCookies");

    if (hasCookies || local === "accept" || local === "refuse") {
      setCookiesView(false);
    } else {
      setCookiesView(true);
    }

    setWindowWidth(updateDimensions());
    window.addEventListener("resize", updateDimensions);

    return () => window.removeEventListener("resize", updateDimensions);
  }, []);

  return (
    <>
      <Container cookiesView={cookiesView} isMobile={showDesktopComponent}>
        <CookiesComponent
          colorText={color_neutral_3}
          buttonLeftLabel={showTextsi18n.buttonLeft}
          buttonRightLabel={showTextsi18n.buttonRigth}
          buttonLeftOnPress={() => {
            cookies.declineCookies();
            setCookiesView(false);
          }}
          buttonRightOnPress={() => {
            cookies.acceptCookies();
            setCookiesView(false);
          }}
        >
          {showTextsi18n.description}
          <ContedHiperLink>
            <HiperLinkText
              onPress={async () => await window.open(showTextsi18n.linkTerms)}
              text={showTextsi18n.textLinkTerms}
            />
          </ContedHiperLink>
        </CookiesComponent>
      </Container>
    </>
  );
};
