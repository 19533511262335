import styled from "styled-components";
import { Typography, color_blue_4 } from "zera";

interface ICookiesTypographyProps {
  onPress: () => void;
}

export const ContedHiperLink = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  @media (max-width: 460px) {
    justify-content: center;
  }
`;

export const HiperLinkText = styled(Typography).attrs(
  ({ onPress }: ICookiesTypographyProps) => ({
    fontFamily: "Inter",
    fontWeight: 600,
    fontSize: "3",
    lineHeight: "1.5",
    color: color_blue_4,
    onPress: onPress,
  })
)``;

export const Container = styled.div<{
  cookiesView: boolean;
  isMobile: boolean;
}>`
  height: auto;
  z-index: 1000;
  display: ${(props) => (props.cookiesView ? "flex" : "none")};
  background-color: transparent;
  margin: auto;
  bottom: ${(props) => (props.isMobile ? "2%" : "0%")};
  position: fixed;
  width: 100%;
  justify-content: center;
`;
