const getCookie = (name) => {
  var cookieArr = document.cookie.split(";");
  for (var i = 0; i < cookieArr.length; i++) {
    var cookiePair = cookieArr[i].split("=");

    if (name == cookiePair[0].trim()) {
      return decodeURIComponent(cookiePair[1]);
    }
  }
  return null;
};

const acceptCookies = () => {
  localStorage.setItem("pureJavaScriptCookies", "accept");
  document.cookie = "Aceite_Cookies=Sim";
};

const declineCookies = () => {
  var res = document.cookie;
  var multiple = res.split(";");
  for (var i = 0; i < multiple.length; i++) {
    var key = multiple[i].split("=");
    document.cookie = key[0] + " =; expires = Thu, 01 Jan 1970 00:00:00 UTC";
  }
  localStorage.setItem("pureJavaScriptCookies", "refuse");
  document.cookie = "Aceite_Cookies=Não";
};

const cookies = { getCookie, acceptCookies, declineCookies };

export default cookies;
