import { Cookies } from "./modules";

type TypeInternationalization = "enUS" | "es" | "ptBR";

export default function Root() {
  const internationalization = localStorage.getItem(
    "@conecta:internationalization"
  ) as TypeInternationalization;

  return (
    <Cookies
      internationalization={
        internationalization?.length > 0 ? internationalization : "ptBR"
      }
    />
  );
}
