import { enUS, es, ptBR } from "../../i18n";

type TypeInternationalization = "enUS" | "es" | "ptBR";

const showTexts = (internationalization: TypeInternationalization) => {
  const i18n = { enUS, es, ptBR };

  return {
    linkTerms: i18n[internationalization].linkTerms,
    description: i18n[internationalization].description,
    buttonRigth: i18n[internationalization].buttonRigth,
    buttonLeft: i18n[internationalization].buttonLeft,
    textLinkTerms: i18n[internationalization].textLinkTerms,
  };
};

export default showTexts;
